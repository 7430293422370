import React from 'react'
import Layout from '../components/layout'
import AdultsGrid from '../components/sections/AdultsGrid'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Spacer from '../components/uielements/Spacer'
import Ergotherapy from '../components/sections/Ergotherapy'
import LogotherapyGrid from '../components/sections/LogotherapyGrid'
import Wrapper from '../components/Wrapper'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import Evaluation from '../components/sections/Evaluation'
import Typeform from '../components/external/Typeform'

const AdultsPage = () => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                <meta property="og:title" content={t('adults-title')} />
            </Helmet>
            <Wrapper>
                <Seo title={t('adults-title')} />
                <AdultsGrid />
                <Spacer top="4" />
                <Typeform width="100%" height="65vh" formID="IFyeSdA8" />
                <Spacer top="2" />
                <LogotherapyGrid postType="adults" />
                <Spacer top="4" />
                <Ergotherapy />
                <Spacer top="4" />
                <Evaluation />
                <Spacer top="4" />
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export default AdultsPage
